exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-dosing-and-administration-js": () => import("./../../../src/pages/dosing-and-administration.js" /* webpackChunkName: "component---src-pages-dosing-and-administration-js" */),
  "component---src-pages-efficacy-js": () => import("./../../../src/pages/efficacy.js" /* webpackChunkName: "component---src-pages-efficacy-js" */),
  "component---src-pages-how-defencath-works-js": () => import("./../../../src/pages/how-defencath-works.js" /* webpackChunkName: "component---src-pages-how-defencath-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ordering-defencath-js": () => import("./../../../src/pages/ordering-defencath.js" /* webpackChunkName: "component---src-pages-ordering-defencath-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-safety-js": () => import("./../../../src/pages/safety.js" /* webpackChunkName: "component---src-pages-safety-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-unsubscribe-confirmation-js": () => import("./../../../src/pages/unsubscribe-confirmation.js" /* webpackChunkName: "component---src-pages-unsubscribe-confirmation-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

